@import "base/variables";
@import "base/normalize";
@import "base/fonts";
@import "base/mixins";
@import "base/magnific";



/* HTML ===================*/

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
  -webkit-tap-highlight-color: transparent !important;
}

body {
  display: block;
  max-width: 100%;
  padding: 0;
  font-family: "maison-neue-book";
  line-height: 1.7;
  font-size: $font-base;
  background-color: $bg1;
  transition: background-color $transition;
  overflow-x: hidden;
}

p {
  margin: 0 0 $padding-small;
  line-height: $line-height;
  color: $text-color;
  transition: all $transition;
}

a {
  text-decoration: none;
  color: $color1;
  transition: all $transition;
  line-height: $line-height;

  &:active,
  &:hover,
  &:focus {
    color: rgba($color1, 0.5);
    outline: none;
  }
}

img,
object,
iframe,
video {
  max-width: 100%;
  height: auto;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: normal;
}

h1,
.h1 {
  margin-top: 0;
  font-family: "maison-neue-demi", sans-serif;
  font-size: $h2;
  line-height: $line-height-heading;
  @media all and (min-width: $tablet) {
    font-size: $h1;
  }
}

h2,
.h2 {
  margin-top: 0;
  font-family: "maison-neue-book", sans-serif;
  font-size: $h2;
  line-height: $line-height-heading;
}

h3,
.h3 {
  margin-top: 0;
  font-family: "maison-neue-book", sans-serif;
  font-size: $h3;
  line-height: $line-height-heading;
}

h4,
.h4 {
  margin-top: 0;
  font-family: "maison-neue-book", sans-serif;
  font-size: $h4;
  line-height: $line-height-heading;
}

h5,
.h5 {
  margin-top: 0;
  font-family: "maison-neue-book", sans-serif;
  font-size: $font-base;
  line-height: $line-height-heading;
}

h6,
.h6 {
  margin-top: 0;
  font-family: "maison-neue-book", sans-serif;
  font-size: $font-small;
  line-height: $line-height-heading;
}

li {
  line-height: $line-height;
}

figure {
  margin: 0;
}

img, article .fluid-width-video-wrapper {
  /*box-shadow: 0 7px 24px rgba(0, 0, 0, 0.27);*/
  border-radius: $border-radius;
  transition: .3s all ease;
  overflow: hidden;
}

a:hover img {
  /*box-shadow: 0 16px 48px rgba(0, 0, 0, 0.4);*/
  border-radius: $border-radius;
  transform: scale(1.1);
}

section {
  @extend .clear;
}



/* Layout ===================*/

.container {
  display: block;
  max-width: $container;
  margin: 0 auto;
}

.container-narrow {
  display: block;
  width: 100%;
  margin: 0 auto;
  @extend .clear;

  @media all and (min-width: $desktop) {
    max-width: 80%;
  }
}

@media all and (min-width: $desktop) {
.breathing-room {
  padding: 0 $padding-large;
}
}

.section-padding {
  padding-top: $padding-small;
  padding-bottom: $padding-small;
  @media all and (min-width: $tablet) {
    padding-top: $padding;
    padding-bottom: $padding;
  }
}

.section-padding-large {
  padding-top: $padding;
  padding-bottom: $padding;
  @media all and (min-width: $tablet) {
    padding-top: $padding-large;
    padding-bottom: $padding-large;
  }
}

.section-padding-small {
  padding-top: $padding-tiny;
  padding-bottom: $padding-tiny;
  @media all and (min-width: $tablet) {
    padding-top: $padding-small;
    padding-bottom: $padding-small;
  }
}

.section-margin-top {
  margin-top: $padding-small;
  @media all and (min-width: $tablet) {
    margin-top: $padding;
  }
}

.section-margin-top-large {
  margin-top: $padding;
  @media all and (min-width: $tablet) {
    margin-top: $padding-large;
  }
}

.section-margin-bottom-small {
  margin-bottom: $padding-tiny;
  @media all and (min-width: $tablet) {
    margin-bottom: $padding-small;
  }
}

.section-padding-bottom {
  padding-bottom: $padding-small;
  @media all and (min-width: $tablet) {
    padding-bottom: $padding;
  }
}

.section-padding-bottom-small {
  padding-bottom: $padding-tiny;
  @media all and (min-width: $tablet) {
    padding-bottom: $padding-small;
  }
}

.clear:before,
.clear:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clear:after {
  clear: both;
  height: 0;
}



/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */

.clear {
  *zoom: 1;
}



/* Grid ===================*/

.row {
  display: block;
  position: relative;
  margin-right: -$gutter;
  margin-left: -$gutter;
  box-sizing: border-box;
  @extend .clear;
}

*[class^="col-sm-"] {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  padding-top: $padding-small;
  padding-right: $gutter;
  padding-bottom: $padding-small;
  padding-left: $gutter;
  @media all and (min-width: $tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.col-sm-3 {
  @media all and (min-width: $tablet) {
    width: 25%;
  }
}

.col-sm-4 {
  @media all and (min-width: $tablet) {
    width: percentage(4/12);
  }
}

.col-sm-5 {
  @media all and (min-width: $tablet) {
    width: percentage(5/12);
  }
}

.col-sm-6 {
  @media all and (min-width: $tablet) {
    width: 50%;
  }
}

.col-sm-7 {
  @media all and (min-width: $tablet) {
    width: percentage(7/12);
  }
}

.col-sm-8 {
  @media all and (min-width: $tablet) {
    width: percentage(8/12);
  }
}

.col-sm-9 {
  @media all and (min-width: $tablet) {
    width: 75%;
  }
}

.col-sm-12 {
  width: 100%;
}



/* Fonts & Typography ===================*/

.gotham {
  font-family: "gotham-ultra";
}

.font-small {
  font-size: $font-small;
}



/* Helpers ===================*/

.iframe-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  background: black;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.inline-block {
  display: inline-block;
}

.list-no-style {
  padding: 0;
  margin: 0 !important;
  list-style: none;
  @media all and (min-width: $tablet) {
    margin: $padding-tiny 0;
  }
}

.list-inline {
  li {
    display: inline-block;
    vertical-align: middle;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.hidden {
  display: none;
}

.link-white {
  color: $white;

  &:active,
  &:hover,
  &:focus {
    color: rgba($white, 0.7);
  }
}

.link-gray {
  color: $gray;

  &:active,
  &:hover,
  &:focus {
    color: rgba($gray, 0.7);
  }
}

.strike-through {
  text-decoration: line-through;
}

.number-circle {
  position: relative;
  width: 80px;
  height: 80px;
  font-size: $h2;
  background: $black;
  border-radius: 50%;
  z-index: 2;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 5px;
    transform: translate(-50%, -50%);
    z-index: 3;
    color: $white;
  }
}

.btn {
  padding: 13px 45px;
  color: $white;
  font-size: $h6;
  text-transform: uppercase;
  background: $black;
  border: 2px solid $black;
  border-radius: $border-radius;
  line-height: 1.2;

  &:focus,
  &:hover,
  &:active {
    color: $black;
    background: $white;
  }
}

.image-full {
  width: 100%;
  height: auto;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.border-radius {
  border-radius: $border-radius;
}

.border-radius-large {
  border-radius: $border-radius-large;
}

.link-block {
  display: block;
}

.link-inline-block {
  display: inline-block;
}

.fade-in {
  opacity: 0;
  animation: fadeElementIn 1s;
  animation-fill-mode: forwards;
}

/* Popup ===================*/

.mfp-img {
  box-shadow: none !important;
  padding-bottom: $padding-large;
}

.mfp-bottom-bar {
  display: none;
}

.mfp-bg {
  background: #222;
  opacity: 1;
}

.mfp-close {
  font-size: 64px;
  font-style: normal;
  line-height: 64px;
  position: absolute;
  top: 0;
  right: 0;
  width: 162px;
  height: 168px;
  padding: 64px;
  text-align: center;
  text-decoration: none;
  opacity: .65;
  color: #fff;
  @media all and (max-width: $desktop) {
  width: 81px;
  height: 84px;
  padding: 32px;
  
  }
}

/* Colors ===================*/

.white {
  color: $white;
}

.color1 {
  color: $color1;
}

.bg2 {
  background: $bg2;
}

.bgblack {
  background: $black;
}



/* General ===================*/

.scene_element {
}

.site-main {
  display: block;
  position: relative;
  @extend .clear;
}

.container {
  @extend .clear;
  padding: 0;
}

.breather {
  padding: 0 $padding-large;
}

@media all and (min-width: 1100px) {
.breather {
  padding: 0;
}}

article {
  section {
    &:not(:first-of-type) {
      padding-top: 0;
    }

    @media all and (min-width: $desktop) {
      &:not(:first-of-type) {
        padding-top: $padding;
      }
    }
  }
}

.sidebar {
  padding-left: 0;
  @media all and (min-width: $tablet) {
    padding-left: $padding-small;
  }
}

.entry-title {
  width: 100%;
  margin: 0 auto;
  @media all and (min-width: $tablet) {
    width: 70%;
  }
}



/* Header ===================*/

.header {
  width: 100%;
  padding: $padding-large 0 0;
  @extend .clear;
}

@media all and (max-width: $tablet) {
.header {
  padding: $padding 0 0;
}}

.brand {
  float: none;
  width: 100%;
  color: $black;
  font-size: $font-small;
  text-align: center;
  transition: .3s all;
  opacity: 1;
  @media all and (min-width: $tablet) {
    float: left;
    width: 65%;
    text-align: left;
  }
  /*.is-exiting & {
    opacity: 0;
  } */ 
}

.logo {
  position: relative;
  display: inline-block;
  transition: .3s background-color;
  &:hover {
    background-color: black !important
  }
  .home & {
    background-color: transparent !important;
  }
  
  @media all and (min-width: $tablet) {
    display: block;
    width: 100%;
  }
}

.site-description {
  position: relative;
  top: 0;
  padding-left: 0;
  font-size: $font-small;
  color: $text-color;
  transition: color $transition;
  @media all and (min-width: $tablet) {
    top: -20px;
    padding-left: 50px;
  }
}

.support {
  float: none;
  position: relative;
  padding: 5px;
  color: $black;
  list-style: none;
  text-align: center;
  background-color: transparent;
  z-index: 5;
  transition: all $transition;
  @media all and (max-width: $tablet) {  
  .home &, .support-dy & {
     right: 0 !important;
  }}
  .support-dy & {
     opacity: 0 !important;
     pointer-events: none;
  }

  ul {
    margin: 10px 10px 5px;
  }

  a {
    display: block;
    @include link($black, $color1);
    transition: none;

    li {
      display: inline-block;
      padding: 0 5px;
      vertical-align: middle;
      transition: all $transition;
      color: black;

      .svg {
        opacity: 1;
        stroke: $black;
        transition: all $transition;
      }
    }

    &:hover {
      .svg {
        stroke: $color1;
      }
    }
  }

  .scrolled .band & {
    position: absolute;
    right: 0 !important;
    padding: $padding-small $padding-small 11px $padding-small;
    background-color: $black;
    margin-top: 8px !important;
    /*box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);    */
    border-radius: 3px 0 0 3px;
    ul {
      margin: 10px 40px 5px 10px;
    }

    a {
      transition: all $transition;

      li {
        color: $white;
        font-family: "maison-neue-demi";                

        .svg {
          stroke: $white;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  @media all and (min-width: $tablet) {
    float: right;
    padding: 0;
    text-align: right;
  }
}

.support-footer {
  transition: .3s opacity;
  
  & * {
    transition: none;
  }
  
  &:hover * {
    fill: $black !important;
    color: white;
    opacity: 1;
    transition: none;
  }
  
  &:hover {
    opacity: .7 !important;
  }
  
  li {
    padding: 0 10px;
  }
  
  a {
    .support-dy & {
      color: white;
    }
  }
  
  .svg {
    stroke: $white;
  }
}

.subpage {
  .logo {
    display: inline-block;
    position: fixed;
    width: 72px;
    height: 72px;
    background: $black;
    border-radius: 50%;
    vertical-align: top;
    z-index: 5;
    left:  $padding-large;
    /*box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);*/
    transition: .3s all;
  	&:hover {
      /*box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);*/
      transform: scale(1.1);
    }
    &:active {
      transform: scale(.9);      
    }
    /*.is-exiting & {
      opacity: 0;
      transform: scale(0);
    }*/
    
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 45px;
      height: auto;
      transform: translate(-50%, -50%);
    }

    &:after {
      display: none;
    }
  }

  @media all and (max-width: $tablet) {
  .logo {
    left: $padding;
  }
  
  }
  .site-description {
    display: block;
    margin-top: 25px;
    padding-left: 0;
    color: $black;
    vertical-align: top;
    text-align: center;
    display: none;
    

    span {
      display: inline-block;
      margin-top: -1px;
    }

    .hidden {
      display: inline-block;
    }
  }  
   @media all and (min-width: $tablet) {
     .brand {
       .site-description {
         display: inline-block;
         margin-top: 48px;
         padding-left: 86px;
       }
     }
   } 
}

.page {
  .content-wrapper {
    position: relative;
  }

  .support {
    position: relative;
    margin-top: 22px;
    color: $black;
    z-index: 5;
  }
  .brand {
    padding-left: $padding-large;
  }
}

.support {
  right: $padding-large;

  .band & {
    position: fixed !important;
  }
}

@media all and (max-width: $tablet) {
.support {
  right: $padding;
}
}

.tab-left {
  position: fixed;
  display: inline-block;
  top: 350px;
  left: 8px;
  margin-top: 60px;
  transform: rotate(-90deg);
  transform-origin: 10% 40%;
  z-index: 4;
  @media all and (min-width: $desktop) {
    left: $padding-small;
  }

  @media all and (min-width: 1240px) {
    left: $padding-large;
  }

  @media all and (min-height: $min-height) {
    top: 50%;
  }

  .svg .icon {
    transition: all $transition;
  }
  a {
    color: black !important;
  }
}

.tab-right {
  position: fixed;
  display: none;
  top: 350px;
  right: 8px;
  padding: 0;
  transform: translate(0, -50%);
  z-index: 4;
  @media all and (min-width: $tablet) {
    display: inline-block;  
  }
  
  @media all and (min-width: $desktop) {
    right: $padding-small;    
  }

  @media all and (min-width: 1240px) {
    right: $padding-large;
  }

  @media all and (min-height: $min-height) {
    top: 50%;
  }
}



/* Home ===================*/

.home {

  .hero {
    padding-top: calc(3 * #{$padding-large}); 
    min-height: 500px; 
  }
  
  .art-container {
    background: url('../images/art.jpg') no-repeat right center;
    background-size: contain;
    width: 100%;
  }
  
  @media all and (min-width: $desktop) {
  .hero {
    height: calc(100vh - (4 * #{$padding-large}));
    padding-top: calc(2 * #{$padding-large});      
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: $padding-large;
    padding-right: $padding-large;
  }
  .band-container {
    min-width: 450px; 
  }
  .art-container {
    height: 60vh;  
    min-height: 600px;  
  }
  }

  @media all and (max-width: $desktop) {
  .art-container,
  .band-container {
    width: 100% !important;
    display: block;
  }
  .art-container {
    background-position: center center;
    height: 100vw;
    margin-top: $padding;    
  }
  .band-container {
    text-align: center;
  }
  }

  @media all and (max-width: $tablet) {
  .brand {
    padding: 0;
  }
  }
  
  .bands {
    margin: 0;
  }

  .header {
    margin: 0 auto;
    position: absolute;
  }

  .logo {
    .logo-svg {
      .svg {
        fill: $black;
        transition: fill $transition;
      }
    }
  }

  .bands {
      a {
        @include link($text-color);
        font-family: "maison-neue-demi";        
      }
  }
}

.home-intro {
  display: block;
  position: relative;
  padding: $padding-large 0 0;
  max-width: 1080px;
  margin: 0 auto;

  .down-arrow {
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translate3d(0, -300px, 0);
    animation: dropBounce 1s linear;
    animation-delay: 2s;
    animation-fill-mode: forwards;

    .arrow {
      fill: $black;
    }
  }
  
  .about-intro {
    padding-top: 10vw;
  }
}

.downyonder-draw {
  position: relative;
  padding: 0;

  .art {
    position: relative;
    width: 70%;
    height: auto;
  }

  #bg {
    fill: #eee;
  }

  #bg,
  #art {
    transition: fill $transition;
  }

  @media all and (min-width: $tablet) {
    top: 0;

    .art {
      width: 100%;
    }
  }
}

.home .bands {
}

.home .bands a {
  color: black;
  display: inline-block;
  width: 100%;
  -webkit-user-select: none;
}

.home .bands img {
  width: 42px;
  top: 3px;
  display: inline-block;
  position: relative;
  margin-right: 2px;
  border-radius: 3px;
}

body.alexa-jenson {

  .home {
    .bands a {
      @include invertedlink($alexa);
    }
  }
}

body.alright {

  .home {
    .bands a {
      @include invertedlink($alright);
    }
  }
}

body.bless-these-sounds-under-the-city {

  .home {

    .bands a {
      @include invertedlink($bless);
    }
  }
}

body.dollar-signs {

  .home {

    .bands a {
      @include invertedlink($dollar);
    }
  }
}

body.george-banda {

  .home {
    .bands a {
      @include invertedlink($george);
    }
  }
}

body.ghost-trees {

  .home {

    .bands a {
      @include invertedlink($ghost);
    }
  }
}

body.junior-astronomers {

  .home {

    .bands a {
      @include invertedlink($junior);
    }
  }
}

body.sinners-and-saints {

  .home {

    .bands a {
      @include invertedlink($sinners);
    }
  }
}

/* What is this anyway ===================*/

.what-is-this {
  padding-top: $padding-large;
  transition: .3s opacity;
  @media all and (min-width: $desktop) {
    opacity: .2;  
  }  
  .scrolled & {
    opacity: 1;
  }
  
  .hero-exit {
    padding: 0;
    text-align: center;
  }

  section {
    padding-top: $padding;
    padding-bottom: $padding;
  }

  h2 {
    text-align: center;
    @media all and (min-width: $tablet) {
      text-align: left;
    }
  }
  
  p a {
    border-bottom: 2px solid #ddd;
  }
  
  .col-sm-8 p {
    margin: 0;
    @media all and (min-width: $tablet) {
      margin: 0 0 $padding-small;
    }
  }

  .sidebar {
    margin-top: 0;
    @media all and (min-width: $tablet) {
      margin-top: $padding-large;
    }
  }

  .partners {
    position: relative;
    padding-bottom: $padding-large;
    margin-bottom: $padding;

    ul {
    margin: 0 auto;
    
      li {
        display: inline-block;
        width: 100%;
        padding-bottom: $padding;
        text-align: center;
        vertical-align: bottom;
      }
    }
    
    img {
      box-shadow: none;
    }
    
    .arrow-down {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      .arrow {
        fill: $black;
      }
    }

    @media all and (min-width: $tablet) {
      padding-bottom: $padding-large*2;

      ul {
        li {
          display: inline-block;
          width: 30%;
          padding-bottom: 0;
          text-align: center;
          vertical-align: middle;

          &:last-child {
            padding-bottom: 2.5vw;
          }
        }
      }
    }
  }

  .credits {
    .footer-list {
      background: transparent;
      color: $black;
    }
  }
}

/* Support ===================*/

.support-dy {
.sub-title {
    padding-bottom: $padding;
  }
  
  .supporter-donate {
    padding-top: $padding-large;
  }
  
  .donate-methods {
    position: relative;
    padding-bottom: $padding-large*2;

    .arrow-down {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      .arrow {
        fill: $black;
      }
    }
  }

  .supporter-donate {
    .entry-header {
      padding-bottom: 0;
      text-align: center;
    }
  }

  .donate-method {
    padding-bottom: $padding-large;
    padding-left: 0;
    
    img {
      margin: $padding 0;
    }
    
    li {
      list-style-type: square;
      margin-left: $padding;
    }
  }

  .donate-header {
    position: relative;
    padding-top: $padding-small;
    padding-bottom: $padding;
    text-align: center;

    .donate-title {
      margin-top: $padding-tiny;
      margin-bottom: $padding-tiny;
      vertical-align: middle;
    }

    .number-circle {
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
      margin-top: 0;
      transform: none;
    }

    .btn {
      position: relative;
      top: 0;
      right: 0;
      margin-top: 0;
      transform: none;
    }
  }

  @media all and (min-width: $tablet) {
    .supporter-donate {
      .entry-header {
        padding-bottom: $padding-small;
        text-align: left;
      }
    }

    .donate-method {
      padding-bottom: $padding-large*2;
      padding-left: 100px;
    }

    .donate-header {
      padding-top: 0;
      padding-bottom: 0;
      text-align: left;

      .number-circle {
        position: absolute;
        top: 50%;
        left: -100px;
        margin-top: -5px;
        transform: translateY(-50%);
      }

      .btn {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -5px;
        transform: translateY(-50%);
      }
    }
  }
}

/* Bands ===================*/

.band {
  .header {
    position: absolute;
  }

  .site-description {
    z-index: 4;
  }

  .band-header-title {
    position: relative;
    width: 100%;
    padding-top: 200px;
    transform: none;
    z-index: 3;
  }

  .entry-header {
    position: relative;
    width: auto;
    min-width: 100%;
    z-index: 3;
  }

  .white-popup {
    position: relative;
    background: transparent;
    padding: 20px;
    width: auto;
    max-width: 500px;
    margin: 20px auto;
  }
  
  .arrow-down {
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
  }

  @media all and (min-width: $tablet) {
    .header {
      position: absolute;
      z-index: 5;
    }

    .entry-header {
      //height: 65vh;
      min-height: 620px;
    }

    .band-header-title {
      padding-top: 20vw;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      z-index: 3;
    }

    .arrow-down {
      bottom: -20vw;
    }
  }

  @media all and (min-width: $desktop) {
    .entry-header {
      position: relative;
      height: auto;
    }

    .band-header-title {
      padding-top: calc(5vw + 150px);
    }

    .arrow-down {
      bottom: -150px;
    }
  }
  
  aside figure {
    margin-bottom: $padding-small;
  }
  
  aside .h5 {
    font-size: 16px;
    line-height: 1.5;
  }
  aside .h5 strong {
    font-weight: normal;
    font-family: "maison-neue-bold";    
  }
}

#band-youtube-video iframe {
  width: 100vw !important;
  left: 0;
}

.band-video-container {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 580px;
  overflow: hidden;

  .band-video {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    object-fit: cover;
  }

  .screen {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 105%;
    background: rgba($white, 0.5);
    /* Old browsers */
    background: linear-gradient(to bottom, rgba($white, 0.5) 0%, $white 85%);
    /* W3C */
    z-index: 2;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media all and (min-width: $tablet) {
    display: block;
    height: 56.25vw;
  }
}

.play-video-container {
  padding: $padding-large $gutter;

  .play-video {
    display: inline-block;
    position: relative;

    &:hover {
      img {
        opacity: 1;
      }
    }
  }

  img {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    border-radius: $border-radius;
    transition: all $transition;
  }

  .play {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @media all and (max-width: 480px) {
  .play {
    width: 50px;
  }}
  

  @media all and (min-width: $tablet) {
    img {
      width: 400px;
    }
  }
}

@media all and (max-width: $desktop) {
.play-video-container {
  padding: $padding-small $gutter;
}
}

.band-intro {
  position: relative;
  background: $bg1;
}

.band-intro * {
  z-index: 3;
}

.band-intro-content {
  h2 {
    font-size: $h3;
  }

  @media all and (min-width: $tablet) {
    padding-right: 3vw;

    h2 {
      font-size: $h2;
    }
  }
}

.band-gallery {
  position: relative;
  z-index: 3;

  img {
    width: 100%;
    height: auto;
  }
}

.hear-more .title {
    font-family: "maison-neue-bold" !important;
}

.hear-more .year {
    font-family: "maison-neue-demi" !important;
    font-size: 16px;
    text-transform: uppercase;
    opacity: .3;
    margin-bottom: $padding-small;
}

.hear-more {
  position: relative;
  z-index: 3;
  
  h3 {
    font-weight: normal;
  strong {
    font-family: "maison-neue-bold" !important;
  }
  }
}

.band-nav {
  position: relative;
  z-index: 10;
  padding-top: $padding-large;

  p {
    margin: 0;
    color: $white;
    font-family: "maison-neue-bold";    
    font-size: 16px;
    opacity: .5;
  }

  h3 {
    margin: 0;
    font-family: "maison-neue-demi";
    color: $white !important;     
  }

  img {
    width: 250px;
    height: auto;
    border-radius: 5px;
  }

  .video-play {
    display: inline-block;
    position: relative;
    padding: $padding-small 0;
  }

  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    transform: translate(-50%,-50%);
  }

  .col-sm-6 {
    text-align: center;
  }

  @media all and (min-width: $tablet) {
    .col-sm-6 {
      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }
}

.blob-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding-top: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 3;
}

.blob {
  position: relative;
  opacity: 0.1;
}







/* Footer ===================*/

.footer-wrapper {
  /*box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);  */
}

.site-footer {
  background: $black;
  @extend .clear;
  position: relative;
  z-index: 10;

  .social {
    padding-bottom: $padding;

    li {
      display: inline-block;

      svg {
        position: relative;
      }

      .svg {
        opacity: 1;

        &.radio {
          top: 3px;
        }
      }

      .icon {
        fill: $white;
      }

      .radio {
        top: 3px;
      }

      .instagram {
        top: 2px;
      }
    }

    .social-name {
      display: none;
    }
  }
}

a.more,
.support-footer a li {
    font-family: "maison-neue-demi" !important;
}

a.more.link-white:hover {
  color: white !important;
  opacity: .7;
  transform: translateZ(0);
}

a.more.link-white svg {
  width: 26px;
  margin-left: 14px;
}

.footer-logo {
  position: relative;
  display: block;
  z-index: 10;
  padding-top: $padding;
  padding-bottom: $padding-small;

  .logo {
    display: inline-block;
    position: relative;
    left: 0;
    width: 144px;
    height: 144px;
    background: transparent !important;
    text-align: center;
    box-shadow: none !important;
    
    img {
      box-shadow: none;
      transform: inherit;
      display: inline !important;
    }
    
    :hover {
      opacity: .7;
    }
  }

  .site-description {
    position: relative;
    display: block;
    top: 100%;
    left: 50%;
    margin-top: 0;
    padding-left: 0;
    color: $white;
    transform: translateX(-50%);
    & .hidden {
      margin-right: 6px;
    }
  }

  @media all and (min-width: $tablet) {
    .site-description {
      position: absolute;
      display: block;
      top: 100%;
      left: 50%;
      margin-top: -50px;
      transform: translateX(-50%);
    }
  }
}

.more {
  span {
    margin-left: 8px;
  }
}

.pricelessmisc {
  position: relative;
  z-index: 5;
  color: $white;

  .svg {
    opacity: 1;
    transform: translateY(3px)
  }
  
  .icon {
    fill: $white;
  }
  a {
    color: $white;
    font-family: "maison-neue-demi";    
  }
}

.social {
  position: relative;
  padding: 0;
  margin: 0 0 $padding-small;
  z-index: 5;

  li {
    position: relative;

    a {
      display: inline-block;
      padding: 0 5px;
      vertical-align: top;

      &:hover {
        opacity: .7;
      }
    }

    .svg {
      opacity: 1;
    }
  }
}

.tab-right {

  .social {


    a {
      outline: none;
      cursor: pointer;

      backface-visibility: hidden;

      &:hover {
        opacity: 1;
      }
    }

    .social-name {
      position: absolute;
      top: -5px;
      right: -3000px;
      padding: 10px 0;
      font-size: $h6;
      color: $black;
      transform: translateX(0);
      backface-visibility: hidden;
    }
  }
}

.footer-list {
  position: relative;
  z-index: 10;
  text-align: center;

  @media all and (min-width: $tablet) {
  .credit-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  }
  
  .primary-credits {
    padding-top: $padding-large;
    padding-bottom: $padding-large;
  }
  
  p {
    color: $white;
  }

  ul {
    margin: 0;
    padding-bottom: $padding;
  }

  h3 {
    font-weight: normal;
    opacity: .5;
    font-size: 22px;
    margin: 0;
    padding-bottom: $padding;
  }

  h4 {
    margin-bottom: $padding-tiny;
    font-family: "maison-neue-demi";
    font-weight: normal;
    font-size: 16px;
    opacity: .5;
    text-transform: uppercase;
  }

  @media all and (min-width: $tablet) {
    
    ul {
      margin: $padding-small 0;
    }
  }
}

.footer-more {
  position: relative;
  z-index: 10;
}



/* Bands ===================*/

.band {
  &.alexa-jenson {
    a {
      @include link($alexa);
    }

    .brand {
      .logo {
        background: $alexa;
      }
    }

    .entry-header {
      a {
        @include link($alexa);
      }

      .svg .arrow {
        fill: $alexa;
      }
    }

    .support {
      a {
        color: black;
      }
      
      .scrolled & {
        background-color: $alexa;

        li {
          a {
            color: $white;
          }

          svg .svg {
            stroke: $white;
          }
        }
      }
    }

    .blob path {
      fill: $alexa;
    }

    .site-footer,
    .band-nav,
    .footer-logo,
    .footer-more,
    .footer-list {
      background: $alexa;

      a {
        @include link($white);
      }
    }
  }

  &.alright {
    a {
      @include link($alright);
    }

    .brand {
      .logo {
        background: $alright;
      }
    }

    .entry-header {
      a {
        @include link($alright);
      }

      .svg .arrow {
        fill: $alright;
      }
    }

    .support {
      a {
        color: black;
      }

      .scrolled & {
        background-color: $alright;

        li {
          a {
            color: $white;
          }

          svg .svg {
            stroke: $white;
          }
        }
      }
    }

    .blob path {
      fill: $alright;
    }

    .site-footer,
    .band-nav,
    .footer-logo,
    .footer-more,
    .footer-list {
      background: $alright;

      a {
        @include link($white);
      }
    }
  }

  &.bless-these-sounds-under-the-city {
    a {
      @include link($bless);
    }

    .brand {
      .logo {
        background: $bless;
      }
    }

    .entry-header {
      a {
        @include link($bless);
      }

      .svg .arrow {
        fill: $bless;
      }
    }

    .support {
      a {
        color: black;
      }
      
      .scrolled & {
        background-color: $bless;

        li {
          a {
            color: $white;
          }

          svg .svg {
            stroke: $white;
          }
        }
      }
    }

    .blob path {
      fill: $bless;
    }

    .site-footer,
    .band-nav,
    .footer-logo,
    .footer-more,
    .footer-list {
      background: $bless;

      a {
        @include link($white);
      }
    }

    @media all and (min-width: $tablet) {
      .band-video-container {
        height: 100vh;
        max-height: 950px;
      }
    }
  }

  &.dollar-signs {
    a {
      @include link($dollar);
    }

    .brand {
      .logo {
        background: $dollar;
      }
    }

    .entry-header {
      a {
        @include link($dollar);
      }

      .svg .arrow {
        fill: $dollar;
      }
    }

    .support {
      a {
        color: black;
      }
      
      .scrolled & {
        background-color: $dollar;

        li {
          a {
            color: $white;
          }

          svg .svg {
            stroke: $white;
          }
        }
      }
    }

    .blob path {
      fill: $dollar;
    }

    .site-footer,
    .band-nav,
    .footer-logo,
    .footer-more,
    .footer-list {
      background: $dollar;

      a {
        @include link($white);
      }
    }
  }

  &.george-banda {
    a {
      @include link($george);
    }

    .brand {
      .logo {
        background: $george;
      }
    }

    .entry-header {
      a {
        @include link($george);
      }

      .svg .arrow {
        fill: $george;
      }
    }

    .support {
      a {
        color: black;
      }
      
      .scrolled & {
        background-color: $george;

        li {
          a {
            color: $white;
          }

          svg .svg {
            stroke: $white;
          }
        }
      }
    }

    .blob path {
      fill: $george;
    }

    .site-footer,
    .band-nav,
    .footer-logo,
    .footer-more,
    .footer-list {
      background: $george;

      a {
        @include link($white);
      }
    }
  }

  &.ghost-trees {
    a {
      @include link($ghost);
    }

    .brand {
      .logo {
        background: $ghost;
      }
    }

    .entry-header {
      a {
        @include link($ghost);
      }

      .svg .arrow {
        fill: $ghost;
      }
    }

    .support {
      ca {
        color: black;
      }
      
      .scrolled & {
        background-color: $ghost;

        li {
          a {
            color: $white;
          }

          svg .svg {
            stroke: $white;
          }
        }
      }
    }

    .blob path {
      fill: $ghost;
    }

    .site-footer,
    .band-nav,
    .footer-logo,
    .footer-more,
    .footer-list {
      background: $ghost;

      a {
        @include link($white);
      }
    }
  }

  &.junior-astronomers {
    a {
      @include link($junior);
    }

    .brand {
      .logo {
        background: $junior;
      }
    }

    .entry-header {
      a {
        @include link($junior);
      }

      .svg .arrow {
        fill: $junior;
      }
    }

    .support {
      a {
        color: black;
      }
      
      .scrolled & {
        background-color: $junior;

        li {
          a {
            color: $white;
          }

          svg .svg {
            stroke: $white;
          }
        }
      }
    }

    .blob path {
      fill: $junior;
    }

    .site-footer,
    .band-nav,
    .footer-logo,
    .footer-more,
    .footer-list {
      background: $junior;

      a {
        @include link($white);
      }
    }
  }

  &.sinners-and-saints {
    a {
      @include link($sinners);
    }

    .brand {
      .logo {
        background: $sinners;
      }
    }

    .entry-header {
      a {
        @include link($sinners);
      }

      .svg .arrow {
        fill: $sinners;
      }
    }

    .support {
      a {
        color: black;
      }
      
      .scrolled & {
        background-color: $sinners;

        li {
          a {
            color: $white;
          }

          svg .svg {
            stroke: $white;
          }
        }
      }
    }

    .blob path {
      fill: $sinners;
    }

    .site-footer,
    .band-nav,
    .footer-logo,
    .footer-more,
    .footer-list {
      background: $sinners;

      a {
        @include link($white);
      }
    }
  }
}



/* Animations ===================*/

.is-exiting {

}

.m-scene {
  /** Basic styles for an animated element */
  .scene_element {
    animation-duration: 0.25s;
    transition-timing-function: ease-in;
    animation-fill-mode: both;
  }

  /** An element that fades in */
  .scene_element--fadein {
    animation-name: fadeIn;
  }

  /** An element that fades in and slides up */
  .scene_element--fadeinup {
    animation-name: fadeInUp;
  }

  /** An element that fades in and slides from the right */
  .scene_element--fadeinright {
    animation-name: fadeInRight;
  }

  &.is-exiting {
    .scene_element {
      animation-direction: alternate-reverse;
    }
  }
}



/* Keyframes ===================*/

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes dropBounce {

  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes fadeElementIn {
  50%
  {
    opacity: 0;
  }
  100%
  {
    opacity: 1;
  }
}