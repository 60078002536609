@mixin invertedlink( $color, $hover: $color, $background: transparent ) {
    color: rgba($color, 0.4);
    background: $background;

    &:focus,
    &:hover,
    &:active {
        color: $hover;
    }
}

@mixin link( $color, $hover: rgba($color, 0.4), $background: transparent ) {
    color: $color;
    background: $background;

    &:focus,
    &:hover,
    &:active {
        color: $hover;
    }
}