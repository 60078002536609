// Grid
$container: 980px;
$tablet: 768px;
$desktop: 1024px;
$min-height: 800px;

//  Colors
$black: #000000;
$white: #ffffff;
$gray: #B0B0B1;

$color1: #000000;

$bg1: $white;
$bg2: #2D000C;

$alexa: #FBB100;
$alright: #00D542;
$bless: #FF6738;
$dollar: #FD0B50;
$george: #F828B7;
$ghost: #0028FC;
$junior: #01B6DE;
$sinners: #9700C9;

$text-color: #333333;

// Fonts & type

$font-base: 22px;

$h1: 70px;
$h2: 36px;
$h3: 32px;
$h4: 26px;
$h5: $font-base;
$h6: 14px;

$font-small: 12px;

$line-height: 1.6;
$line-height-heading: 1.2;

// Padding
$padding: 32px;
$padding-small: floor($padding/2);
$padding-tiny: floor($padding-small/2);
$padding-large: floor($padding*2);
$gutter: 16px;

// Misc
$transition: 300ms;
$border-radius: 4px;
$border-radius-large: $border-radius*2;

$box-shadow: /*0 5px 14px 0 rgba(0, 0, 0, 0.3)*/;