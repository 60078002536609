@font-face {
    font-family: 'maison-neue-bold';
    src: url('../font/MaisonNeue-Bold.eot'); /* IE9 Compat Modes */
    src: url('../font/MaisonNeue-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/MaisonNeue-Bold.woff') format('woff'), /* Pretty Modern Browsers */
    url('../font/MaisonNeue-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../font/MaisonNeue-Bold.svg#MaisonNeue-Bold') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'maison-neue-demi';
    src: url('../font/MaisonNeue-Demi.eot'); /* IE9 Compat Modes */
    src: url('../font/MaisonNeue-Demi.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/MaisonNeue-Demi.woff') format('woff'), /* Pretty Modern Browsers */
    url('../font/MaisonNeue-Demi.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../font/MaisonNeue-Demi.svg#MaisonNeue-Demi') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'maison-neue-book';
    src: url('../font/maisonneuebook.eot'); /* IE9 Compat Modes */
    src: url('../font/maisonneuebook.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/maisonneuebook.woff') format('woff'), /* Pretty Modern Browsers */
    url('../font/maisonneuebook.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../font/maisonneuebook.svg#MaisonNeue-Book') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'maison-neue-medium';
    src: url('../font/MaisonNeue-Medium.eot'); /* IE9 Compat Modes */
    src: url('../font/MaisonNeue-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/MaisonNeue-Medium.woff') format('woff'), /* Pretty Modern Browsers */
    url('../font/MaisonNeue-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../font/MaisonNeue-Medium.svg#MaisonNeue-Medium') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'maison-neue-light';
    src: url('../font/MaisonNeue-Light.eot'); /* IE9 Compat Modes */
    src: url('../font/MaisonNeue-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/MaisonNeue-Light.woff') format('woff'), /* Pretty Modern Browsers */
    url('../font/MaisonNeue-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../font/MaisonNeue-Light.svg#MaisonNeue-Light') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'gotham-ultra';
    src: url('../font/gotham-ultra-webfont.eot'); /* IE9 Compat Modes */
    src: url('../font/gotham-ultra-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/gotham-ultra-webfont.woff') format('woff'), /* Pretty Modern Browsers */
    url('../font/gotham-ultra-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../font/gotham-ultra-webfont.svg#gotham-ultra-webfont') format('svg'); /* Legacy iOS */
}